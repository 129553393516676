import React, { useEffect } from "react";
import { clearTokens, getLogOutUrl } from "../auth";

const LogOut = () => {
  useEffect(() => {
    // Clear local authentication tokens
    clearTokens();

    localStorage.setItem("subscriptionSpend", "0");
    localStorage.setItem("subscriptionLimit", "0");

    // Retrieve redirect path from URL
    const params = new URLSearchParams(window.location.search);
    const redirectPath = params.get("redirect") || "/dashboard";

    // Store the redirect path in localStorage
    localStorage.setItem("postLoginRedirect", redirectPath);

    // Redirect to Cognito logout
    const logoutUrl = getLogOutUrl();
    window.location.href = logoutUrl;
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <p>Logging you out...</p>
    </div>
  );
};

export default LogOut;
