import axios from "axios";
import { getValidAccessToken } from "./token";
import { makeApiPath } from "./makeApiPath";
import { clearTokens } from "../auth";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiCall = async (
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  data: any = null,
  auth = true
) => {
  try {
    const config = {
      method,
      url: makeApiPath(endpoint, auth),
      headers: {},
      data: undefined,
    };
    if (data) {
      config.data = data;
    }
    if (auth) {
      const token = await getValidAccessToken();
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await apiClient(config);
    return response.data;
  } catch (error) {
    if (error instanceof Error && error.message === "no_access_token") {
      clearTokens();
    }
    throw error;
  }
};
