import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Card,
  Spinner,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import TurndownService from "turndown";
import { apiCall } from "../utils/api";
import DiffViewer, { DiffMethod } from "react-diff-viewer";
import StoryEditor from "./editor/StoryEditor";
import { handleApiErrors } from "../utils/handleApiErrors";
import ErrorAlert from "./ErrorAlert";
import { getSubscriptionSpend } from "../utils/getSubscriptionSpend";
import { SubscriptionContext } from "../App";

const POLLING_INTERVAL = 2000;

const Expander = () => {
  const [textData, setTextData] = useState(
    () => localStorage.getItem("expander_textData") || ""
  );
  const [markdownData, setMarkdownData] = useState(
    () => localStorage.getItem("expander_markdownData") || textData
  );
  const [response, setResponse] = useState<string | null>(
    localStorage.getItem("expander_response") ||
      "The expanded text will show here..."
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("enhanced");
  const { setSubscriptionSpend, setSubscriptionLimit } =
    useContext(SubscriptionContext);
  const [pollingError, setPollingError] = useState<string | null>(null);
  const [processingMessage, setProcessingMessage] = useState("");

  useEffect(() => {
    localStorage.setItem("expander_textData", textData);
  }, [textData]);

  useEffect(() => {
    localStorage.setItem("expander_markdownData", markdownData);
  }, [markdownData]);

  useEffect(() => {
    if (response !== null) {
      localStorage.setItem("expander_response", response);
    }
  }, [response]);

  const pollReviewStatus = (reference: string) => {
    let placeholderText = "Processing";
    const poll = async () => {
      try {
        const statusData = await apiCall(`/get-offline-status`, "POST", {
          reference,
        });

        if (statusData.status === "COMPLETE") {
          const subscriptionSpendData = getSubscriptionSpend(statusData);
          setSubscriptionSpend(subscriptionSpendData.creditsConsumed);
          setSubscriptionLimit(subscriptionSpendData.creditLimit);

          setResponse((statusData.aiResponse ?? "").trimStart());
          setProcessingMessage("");
          setPollingError(null);
        } else if (
          statusData.status === "ERROR" ||
          statusData.status === "ERROR_LACK_CREDIT" ||
          statusData.status === "ERROR_NOT_FOUND"
        ) {
          setPollingError(statusData.errorDetails || "Unknown error occurred.");
          setProcessingMessage("");
          setErrorMessages(handleApiErrors([statusData.status]));
        } else {
          // Still in progress, continue polling
          setProcessingMessage(placeholderText);
          placeholderText += ".";
          setTimeout(poll, POLLING_INTERVAL);
        }
      } catch (error) {
        console.error("Error polling status:", error);
        setPollingError("Error polling status. Please try again.");
        setProcessingMessage("");
      }
    };

    poll();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setResponse(null);
    setPollingError(null);
    setProcessingMessage("");

    try {
      const turndownService = new TurndownService();
      const input = turndownService.turndown(textData).trimStart();
      setMarkdownData(input);
      setErrorMessages([]);
      setResponse("Hang on a tick...");

      const data = await apiCall("/process-prompt", "POST", {
        prompt: "expander",
        offline: true,
        textData: input,
      });

      if (data.reference) {
        pollReviewStatus(data.reference);
      } else if (data.jobStatus === "COMPLETE") {
        setResponse((data.aiResponse ?? "").trimStart());
      } else {
        setErrorMessages(handleApiErrors(data.jobStatus));
      }

      const subscriptionSpendData = getSubscriptionSpend(data);
      setSubscriptionSpend(subscriptionSpendData.creditsConsumed);
      setSubscriptionLimit(subscriptionSpendData.creditLimit);
    } catch (error) {
      setErrorMessages(handleApiErrors(error));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditorChange = (_: any, editor: any) => {
    const data = editor.getData();
    setTextData(data);
  };

  return (
    <Container>
      <h2>Expander</h2>
      <div className="mt-3">
        The expander takes some text and tries to pad it out with creative
        wording and ideas.
      </div>
      <div className="mt-3">
        Look below for the "Enhanced Text" and "Differences".
      </div>
      <ErrorAlert errorMessages={errorMessages} />
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTextData">
              <Form.Label>Text</Form.Label>
              <StoryEditor
                data={textData}
                onChange={handleEditorChange}
                characterLimit={1024 * 2}
                placeholder="Text to enhance..."
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Expand"}
            </Button>
          </Form>

          {processingMessage && (
            <Alert variant="info" className="mt-3">
              {processingMessage}
            </Alert>
          )}

          {pollingError && (
            <Alert variant="danger" className="mt-3">
              {pollingError}
            </Alert>
          )}

          {response && !pollingError && (
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k || "enhanced")}
              className="mt-3"
            >
              <Tab eventKey="enhanced" title="Enhanced Text">
                <Alert variant="info" className="mt-3">
                  <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
                    {response}
                  </ReactMarkdown>
                </Alert>
              </Tab>
              <Tab eventKey="diff" title="Differences">
                <div className="mt-3">
                  <DiffViewer
                    oldValue={markdownData}
                    newValue={response}
                    splitView={true}
                    disableWordDiff={false}
                    hideLineNumbers={true}
                    compareMethod={DiffMethod.WORDS}
                    leftTitle={"Original"}
                    rightTitle={"Enhanced"}
                  />
                </div>
              </Tab>
            </Tabs>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Expander;
