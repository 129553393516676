import { clearTokens, getAccessToken, refreshAccessToken } from "../auth";

export const getValidAccessToken = async () => {
  let token = getAccessToken();
  if (!token) {
    throw new Error("no_access_token");
  }

  try {
    // Decode the token to check the expiration
    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const now = Math.floor(Date.now() / 1000);

    const paddedExpiry = (tokenPayload.exp as number) - 30;
    if (paddedExpiry < now) {
      token = await refreshAccessToken();
    }
  } catch (error) {
    clearTokens();
    throw new Error("no_access_token");
  }

  return token;
};
