import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import { apiCall } from "../utils/api";
import { getAccessToken } from "../auth";

const ProcessPrompt = () => {
  const [prompt, setPrompt] = useState(localStorage.getItem("prompt") || "");
  const [p1] = useState(localStorage.getItem("p1") || "");
  const [p2] = useState(localStorage.getItem("p2") || "");
  const [p3] = useState(localStorage.getItem("p3") || "");
  const [p4] = useState(localStorage.getItem("p4") || "");
  const [p5] = useState(localStorage.getItem("p5") || "");
  const [p6] = useState(localStorage.getItem("p6") || "");
  const [p7] = useState(localStorage.getItem("p7") || "");
  const [p8] = useState(localStorage.getItem("p8") || "");
  const [p9] = useState(localStorage.getItem("p9") || "");
  const [p10] = useState(localStorage.getItem("p10") || "");
  const [textData, setTextData] = useState(
    localStorage.getItem("textData") || ""
  );
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("prompt", prompt);
  }, [prompt]);

  useEffect(() => {
    localStorage.setItem("p1", p1);
  }, [p1]);

  useEffect(() => {
    localStorage.setItem("p2", p2);
  }, [p2]);

  useEffect(() => {
    localStorage.setItem("p3", p3);
  }, [p3]);

  useEffect(() => {
    localStorage.setItem("p4", p4);
  }, [p4]);

  useEffect(() => {
    localStorage.setItem("p5", p5);
  }, [p5]);

  useEffect(() => {
    localStorage.setItem("p6", p6);
  }, [p6]);

  useEffect(() => {
    localStorage.setItem("p7", p7);
  }, [p7]);

  useEffect(() => {
    localStorage.setItem("p8", p8);
  }, [p8]);

  useEffect(() => {
    localStorage.setItem("p9", p9);
  }, [p9]);

  useEffect(() => {
    localStorage.setItem("p10", p10);
  }, [p10]);

  useEffect(() => {
    localStorage.setItem("textData", textData);
  }, [textData]);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!getAccessToken()) {
      setError("You must be logged in to submit the prompt.");
      return;
    }

    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      const data = await apiCall("/process-prompt", "POST", {
        prompt,
        p1,
        p2,
        p3,
        p4,
        p5,
        p6,
        p7,
        p8,
        p9,
        p10,
        textData,
        model: "mini",
      });
      setResponse(data);
    } catch (err) {
      setError("An error occurred while submitting the prompt.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2>Process Prompt</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="prompt">
          <Form.Label>Prompt</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
        </Form.Group>

        {[...Array(10)].map((_, i) => (
          <Form.Group controlId={`p${i + 1}`} key={i}>
            <Form.Label>{`Parameter ${i + 1}`}</Form.Label>
            <Form.Control
              type="text"
              value={
                // eslint-disable-next-line
                eval(`p${i + 1}`)
              }
              onChange={() =>
                // eslint-disable-next-line
                eval(`setP${i + 1}(e.target.value)`)
              }
              placeholder={`Enter value for p${i + 1}...`}
            />
          </Form.Group>
        ))}

        <Form.Group controlId="textData">
          <Form.Label>Text Data</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            value={textData}
            onChange={(e) => setTextData(e.target.value)}
            placeholder="Enter your text data here..."
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="mt-3"
          disabled={loading}
        >
          Submit
        </Button>
      </Form>
      {loading && (
        <div className="mt-3">
          <span role="img" aria-label="thinking robot">
            🤖
          </span>
          <span className="ml-2">Processing...</span>
        </div>
      )}
      {response && (
        <Alert variant="info" className="mt-3">
          <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
            {response.response}
          </ReactMarkdown>
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default ProcessPrompt;
