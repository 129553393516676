import { useState, useEffect, useContext } from "react";
import { apiCall } from "../utils/api";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import Loader from "./Loader";
import ErrorAlert from "./ErrorAlert";
import SubscribeNow from "./SubscribeNow";
import { SubscriptionContext } from "../App";
import { getSubscriptionSpend } from "../utils/getSubscriptionSpend";

const ManageSubscription = () => {
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [onTrial, setOnTrial] = useState<boolean>(false);
  const [premium, setPremium] = useState<boolean>(false);
  const [ending, setEnding] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { setSubscriptionSpend, setSubscriptionLimit } =
    useContext(SubscriptionContext);

  useEffect(() => {
    const resolveSubscription = async () => {
      try {
        const response = await apiCall("/get-user", "POST", {});

        const subscription = response.subscription ?? "";
        setHasSubscription(response.hasSubscription);
        setOnTrial(subscription === "");
        setPremium(subscription === "monthly");
        setEnding(subscription === "ending");
        setCancelled(subscription === "cancelled");
        localStorage.setItem("subscription", subscription);
        const subscriptionSpendData = getSubscriptionSpend(response);
        setSubscriptionSpend(subscriptionSpendData.creditsConsumed);
        setSubscriptionLimit(subscriptionSpendData.creditLimit);
      } catch (error) {
        setErrorMessages([
          "There was an error checking your subscription. Please try again later.",
        ]);
        console.error("Error getting for subscription:", error);
      }

      setLoading(false);
    };

    resolveSubscription();
  }, [setSubscriptionSpend, setSubscriptionLimit]);

  if (loading) {
    return (
      <div>
        <p>We are processing your subscription. Please wait...</p>
        <Loader />
      </div>
    );
  }

  if (errorMessages?.length > 0) {
    return <ErrorAlert errorMessages={errorMessages} />;
  }

  return (
    <>
      {premium && (
        <>
          <div>
            <p>
              <strong>You have access to premium features.</strong>
            </p>
          </div>
          <div>
            If you need to manage your subscription, navigate to the Stripe
            portal:
          </div>
          <div className="p-3">
            <ManageSubscriptionButton />
          </div>
        </>
      )}
      {onTrial && (
        <div>
          <p>
            You are on a trial and have limitted access to the premium features.
          </p>
        </div>
      )}
      {ending && (
        <>
          <div>
            <p>Your subscription is ending soon...</p>
          </div>
          <div className="p-3">
            <ManageSubscriptionButton />
          </div>
        </>
      )}
      {cancelled && (
        <div>
          <p>
            Your subscription has ended, you no longer have access to the
            premium features.
          </p>
        </div>
      )}
      {!hasSubscription && (
        <div>
          <p>
            Hit <SubscribeNow /> to sign up!
          </p>
        </div>
      )}
    </>
  );
};

export default ManageSubscription;
