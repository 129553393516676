import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { exchangeCodeForTokens } from "../auth";
import Loader from "./Loader";
import ErrorAlert from "./ErrorAlert";
import { handleApiErrors } from "../utils/handleApiErrors";

const Callback = () => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleCodeExchange = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        try {
          await exchangeCodeForTokens(code);
        } catch (error) {
          setErrorMessages(handleApiErrors(error));
          console.error(error);
        }
      } else {
        setErrorMessages(["login_flow_error"]);
      }
    };

    handleCodeExchange();
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {errorMessages?.length > 0 ? (
        <ErrorAlert errorMessages={errorMessages} />
      ) : (
        <>
          <Loader />
          <p>Signing you in...</p>
        </>
      )}
    </div>
  );
};

export default Callback;
